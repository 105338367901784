var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "book-class",
    },
    [
      _c("server-time-clock"),
      _c(
        "b-row",
        {
          attrs: {
            "align-h": "between",
          },
        },
        [
          _c(
            "b-col",
            {
              attrs: {
                md: "9",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "wuwow-card phone-width schedule-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-card-head text-center",
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "wuwow-card-title",
                        },
                        [_vm._v(_vm._s(_vm.$t("bookClassTable")))]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-card-body",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "set-bind-notify-section",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "set-bind-notify",
                              attrs: {
                                href: "/dojo/account/profile#notification_push",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["far", "bell"],
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("setBookClassAlert"))),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mobile-message text-center",
                        },
                        [
                          _c("br"),
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                variant: "primary",
                                show: "",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("cilckDateToBookClass")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "calendar-wrapper",
                        },
                        [
                          _c("vue-full-calendar", {
                            ref: "vue-calendar",
                            attrs: {
                              options: _vm.calendarOptions,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "are-progress-info",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "txt-finish-num",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("countBookClass")) +
                                  " : " +
                                  _vm._s(
                                    `${_vm.weekClassCount}${_vm.$t("class")}`
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "txt-lag-num",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("countBookClassBbhind")) +
                                  " : " +
                                  _vm._s(
                                    `${_vm.weekSuggestClass}${_vm.$t("class")}`
                                  )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-col",
            {
              attrs: {
                md: "3",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "wuwow-right-side",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-right-side-head",
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "wuwow-right-side-title",
                        },
                        [_vm._v("訂課須知")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-right-side-body",
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            srcset:
                              "https://cdn.wuwow.tw/vip/class_time_mobile.jpg",
                            media: "(max-width: 768px)",
                          },
                        }),
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: {
                            srcset:
                              "https://cdn2.wuwow.tw/wuwow-junior/vip/class-time-web.svg",
                            alt: "Class Schedule",
                          },
                        }),
                      ]),
                      _c("br"),
                      _c("br"),
                      _vm._l(_vm.note, function (data, index) {
                        return _c(
                          "b-alert",
                          {
                            key: index,
                            attrs: {
                              show: "",
                              variant: "secondary",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "title",
                              },
                              [_vm._v(_vm._s(data.title))]
                            ),
                            _c("br"),
                            _c("span", {
                              staticClass: "content",
                              domProps: {
                                innerHTML: _vm._s(data.content),
                              },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c("application-modal", {
        key: _vm.reloadBooking,
        attrs: {
          bookingDate: _vm.bookingDate,
        },
        on: {
          bookingClassChange: _vm.bookingClassChange,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "monthModal",
            title: "訊息",
            "ok-title": "確定",
            "cancel-title": "取消",
            centered: "",
            "header-bg-variant": "primary",
          },
          on: {
            ok: function ($event) {
              return _vm.switchMonth()
            },
          },
          model: {
            value: _vm.monthModal,
            callback: function ($$v) {
              _vm.monthModal = $$v
            },
            expression: "monthModal",
          },
        },
        [
          _c(
            "h4",
            {
              staticClass: "text-center",
            },
            [
              _vm._v("是否跳至"),
              _c(
                "span",
                {
                  staticClass: "text-danger",
                },
                [_vm._v(_vm._s(_vm.formatMonth(_vm.toMonth)))]
              ),
              _vm._v("月進行訂課"),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }