var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "transition",
        {
          attrs: {
            name: "page-fade",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show_select_alert,
                  expression: "show_select_alert",
                },
              ],
              staticClass: "float-modal",
              staticStyle: {
                height: "auto",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "wuwow-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-card-head",
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "wuwow-card-title",
                          attrs: {
                            align: "center",
                          },
                        },
                        [_vm._v("是否取消訂課 ?")]
                      ),
                    ]
                  ),
                ]
              ),
              _c("p", {
                staticClass: "text-center",
                staticStyle: {
                  "font-size": "18px",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.select_alert_message),
                },
              }),
              _c("hr"),
              this.cancel_class.cancel_class_type === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "text-align": "left",
                        padding: "0px 15px 0px 15px",
                      },
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "text-center",
                        },
                        [
                          _c("i", {
                            staticClass: "far fa-frown",
                          }),
                          _vm._v(" 請選擇取消原因"),
                          _c(
                            "span",
                            {
                              staticClass: "red-text",
                              staticStyle: {
                                "font-size": "15px",
                              },
                            },
                            [_vm._v("(必選)")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "cancel-book-reason-box",
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.cancel_class_options,
                              name: "radios-stacked",
                              stacked: "",
                            },
                            model: {
                              value: _vm.cancel_class.cancel_class_reason_value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cancel_class,
                                  "cancel_class_reason_value",
                                  $$v
                                )
                              },
                              expression:
                                "cancel_class.cancel_class_reason_value",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.cancel_class.cancel_class_reason_value === "38" ||
                      _vm.cancel_class.cancel_class_reason_value === "39" ||
                      _vm.cancel_class.cancel_class_reason_value === "42"
                        ? _c("b-form-textarea", {
                            attrs: {
                              id: "textarea",
                              placeholder: "請輸入原因...(請勿包含空白)",
                              rows: "3",
                              "max-rows": "6",
                            },
                            model: {
                              value: _vm.cancel_class.cancel_class_reason_text,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cancel_class,
                                  "cancel_class_reason_text",
                                  $$v
                                )
                              },
                              expression:
                                "cancel_class.cancel_class_reason_text",
                            },
                          })
                        : _vm._e(),
                      _c("br"),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "text-center",
                    },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                md: "auto",
                              },
                              on: {
                                click: _vm.cancel_class_alert_cancel,
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                md: "auto",
                                variant: "primary",
                              },
                              on: {
                                click: _vm.cancel_class_alert_ok,
                              },
                            },
                            [_vm._v("確定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
        ]
      ),
      _c("h3", [_vm._v("您已預約的課程如下:")]),
      _c("br"),
      _c(
        "transition",
        {
          attrs: {
            name: "page-fade",
          },
        },
        [
          _c(
            "b-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.alert_message,
                  expression: "alert_message",
                },
              ],
              attrs: {
                show: _vm.dismissCountDown,
                dismissible: "",
                variant: _vm.successAlert ? "success" : "danger",
              },
              on: {
                "dismiss-count-down": _vm.countDownChanged,
              },
            },
            [_vm._v(_vm._s(_vm.alert_message))]
          ),
        ],
        1
      ),
      _c(
        "table",
        {
          staticClass: "table table-hover table-clsapc-record",
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "tableScrollBar",
            },
            [
              _c("table", [
                _vm.todayClass.length === 0
                  ? _c(
                      "h4",
                      {
                        staticClass: "text-center",
                      },
                      [
                        _c(
                          "b-badge",
                          {
                            attrs: {
                              variant: "danger",
                            },
                          },
                          [_vm._v("當日尚未訂課")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "tbody",
                      _vm._l(_vm.todayClass, function (item) {
                        return _c(
                          "tr",
                          {
                            key: item.application_id,
                          },
                          [
                            _c("td", [_vm._v(_vm._s(item.classTime))]),
                            _c(
                              "td",
                              [
                                item.enable1on1Link
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "lnk-clsapc-1on1",
                                        attrs: {
                                          href: "javascript:;",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openUpdateBookingModal(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "fas fa-file-upload fa-lg",
                                        }),
                                        _vm._v(_vm._s(item.class_type_title)),
                                      ]
                                    )
                                  : [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "txt-clsapc-type",
                                        },
                                        [_vm._v(_vm._s(item.class_type_title))]
                                      ),
                                    ],
                              ],
                              2
                            ),
                            _c("td", [
                              _vm
                                .moment(item.class_time)
                                .diff(_vm.moment(_vm.currentTime), "seconds") <
                                900 ||
                              _vm.canNotCanlcelBook(
                                item.class_type_id,
                                item.cancel_status
                              )
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        title:
                                          "1.課程已結束 2.課程15分鐘後開始 3. 新身分需24小時前才可以取消",
                                      },
                                    },
                                    [_vm._v("無法取消")]
                                  )
                                : _vm
                                    .moment(item.class_time)
                                    .diff(
                                      _vm.moment(_vm.currentTime),
                                      "seconds"
                                    ) > 900
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "red-text",
                                      attrs: {
                                        href: "javascript:;",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.show_cancel_class_alert(
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消預約")]
                                  )
                                : _c("span", [_vm._v("處理中...")]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
              ]),
            ]
          ),
        ]
      ),
      _c("br"),
      _c("br"),
      _c("b-row", {
        staticClass: "text-center",
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c("table", [
      _c(
        "thead",
        {
          staticClass: "thead-default",
        },
        [
          _c("tr", [
            _c("th", [_vm._v("上課時間")]),
            _c("th", [_vm._v("課程型態")]),
            _c("th", [_vm._v("操作")]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }